import { Controller } from '@hotwired/stimulus'
import Collapse from 'bootstrap5/js/src/collapse'

// Connects to data-controller="bs-collapse"
export default class extends Controller {
  static targets = ['collapse']

  connect() {
    this.collapse = new Collapse(this.collapseTarget, { toggle: false })
  }

  toggle() {
    this.collapse.toggle()
  }

  disconnect() {
    this.collapse.dispose()
  }
}
