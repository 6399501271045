import 'cookieconsent'
import Cookies from 'js-cookie'
import once from 'lodash/once'
import defer from 'lodash/defer'

const I18n = {
  t(key) {
    const pl = {
      'cookie_consent.policy': "Polityka Cookie",
      'cookie_consent.message': "Klikając na ten przycisk, zezwalają nam Państwo na poprawę komfortu użytkowania naszej strony internetowej. W każdej chwili mogą Państwo zmienić tę decyzję.",
      'cookie_consent.allow': "Tak, zgadzam się",
      'cookie_consent.deny': "Odmowa wykorzystania wszystkich danych",
      'cookie_consent.link': "Szczegóły",
    }
    return pl[key]
  }
}

let isTrackingActive = false

const onConsent = once(function () {
  const unixMilliseconds = (new Date()).getTime()
  // See app/controllers/concerns/cookie_consent.rb
  Cookies.set('cookieconsent_time', Math.floor(unixMilliseconds / 1000))
  Cookies.set('cookieconsent_status', 'allow')
})

const cookieConsent = () => {
  return new Promise((resolve) => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000000",
          text: "#ffffff"
        },
        button: {
          background: "#16b00a",
          text: "#ffffff"
        }
      },
      location: false,
      revokable: true,
      theme: "classic",
      type: "opt-in",
      content: {
        policy: I18n.t('cookie_consent.policy'),
        message: I18n.t('cookie_consent.message'),
        allow: I18n.t('cookie_consent.allow'),
        deny: I18n.t('cookie_consent.deny'),
        link: I18n.t('cookie_consent.link'),
        href: "/privacy-policy"
      },
      onInitialise: function (state) {
        isTrackingActive = (state === "allow");
      },
      onStatusChange: function (state) {
        if (state === "allow") onConsent()
        if ((state === "allow") !== isTrackingActive) defer(() => location.reload())
      },
      onRevokeChoice: function () {
        // ahoy.track("Cookie consent revoke choice");
      },
    }, (popup) => {
      resolve(() => {
        popup.setStatus(cookieconsent.status.dismiss);
      })
    })
  })
}

export default cookieConsent
