import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'

// Connects to data-controller="testimonial-slider"
export default class extends Controller {
  static targets = ['container']

  connect() {
    this.slider = tns({
      container: this.containerTarget,
      items: 1,
      slideBy: 'page',
      autoplay: false,
      controls: false,
      nav: true,
      navPosition: 'bottom',
      speed: 500,
      responsive: {
        960: {
          items: 2
        },
        1320: {
          items: 3
        }
      }
    })
  }

  disconnect() {
    this.slider.destroy()
  }
}
