import { Controller } from '@hotwired/stimulus'
import { icons } from 'feather-icons'

// Connects to data-controller="feather"
export default class extends Controller {
  connect() {
    this.replaceElement(this.element, this.element.dataset.name)
  }

  replaceElement(element, name) {
    const elementAttrs = this.getAttrs(element)
    delete elementAttrs['data-controller']

    const svgString = icons[name].toSvg(elementAttrs)
    const svgDocument = new DOMParser().parseFromString(
      svgString,
      'image/svg+xml',
    )
    const svgElement = svgDocument.querySelector('svg')

    element.parentNode.replaceChild(svgElement, element)
  }

  getAttrs(element) {
    return Array.from(element.attributes).reduce((attrs, attr) => {
      attrs[attr.name] = attr.value
      return attrs
    }, {})
  }
}
