import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="navbar"
export default class extends Controller {
  static classes = ['scrolled']

  connect() {
    this.toggle()
  }

  toggle() {
    if (window.scrollY === 0) {
      this.element.classList.remove(this.scrolledClass)
    } else {
      this.element.classList.add(this.scrolledClass)
    }
  }
}
